import { styled } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";

const MainContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
	margin: "4rem 0 0",
	backgroundColor: "#FAFAFA",
}));

const BANNER_ASPECT_RATIO_WIDTH = 325;
const BANNER_ASPECT_RATIO_HEIGHT = 136;
const BANNER_ASPECT_RATIO = BANNER_ASPECT_RATIO_WIDTH / BANNER_ASPECT_RATIO_HEIGHT;
const BANNER_BASE_PATH = (process.env.CDN_URL || "/") + "images/uploads/homepage_banner_kn-IN";

const CoverBannerSection = () => {
	const bannerRef = React.useRef<HTMLPictureElement>(null);
	const [bannerDimensions, setBannerDimensions] = React.useState<[number, number]>([
		BANNER_ASPECT_RATIO_WIDTH,
		BANNER_ASPECT_RATIO_HEIGHT,
	]);
	const { locale } = useRouter();
	const localeValue = locale || "en-IN";
	let label = "HSR Layout";
	let lat = 12.9121181;
	let lon = 77.6445548;
	const defaultUrlpath = encodeURIComponent(
		`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${label},${lon},${lat}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${localeValue || "en-IN"}`,
	);

	// Precompute the base banner path (without dimensions)
	const baseBannerPathWithLocale = React.useMemo(() => {
		return `${BANNER_BASE_PATH}.jpg`;
	}, []);

	// Helper to get the complete banner path including dimensions
	const getBannerPath = (width: number, height: number) => `${baseBannerPathWithLocale}/${width}/${height}`;

	const [bannerPath, setBannerPath] = React.useState<string>(
		getBannerPath(BANNER_ASPECT_RATIO_WIDTH, BANNER_ASPECT_RATIO_HEIGHT),
	);

	React.useEffect(() => {
		const updateBannerDimensions = () => {
			if (bannerRef.current) {
				// Calculate container padding based on a multi-level parent
				const container = bannerRef.current.parentElement?.parentElement?.parentElement?.parentElement;
				const containerPadding = container
					? parseInt(window.getComputedStyle(container).getPropertyValue("padding-left"), 10) * 2
					: 0;
				const availableWidth = window.innerWidth - containerPadding;
				const bannerWidth = availableWidth;
				const bannerHeight = Math.floor(availableWidth / BANNER_ASPECT_RATIO);
				setBannerDimensions([bannerWidth, bannerHeight]);
				setBannerPath(getBannerPath(bannerWidth, bannerHeight));
			}
		};

		// Initial calculation and update on window resize
		updateBannerDimensions();
		window.addEventListener("resize", updateBannerDimensions);
		return () => window.removeEventListener("resize", updateBannerDimensions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<MainContainer>
				<picture
					ref={bannerRef}
					style={{
						width: bannerDimensions[0] ? `${bannerDimensions[0]}px` : `${BANNER_ASPECT_RATIO_WIDTH}px`,
						height: bannerDimensions[1] ? `${bannerDimensions[1]}px` : `${BANNER_ASPECT_RATIO_HEIGHT}px`,
						maxWidth: "600px",
						display: "block",
					}}
				>
					<source
						srcSet={bannerPath}
						media="(max-width: 600px)"
						type="image/webp"
						sizes={`${bannerDimensions[0]}px`}
					/>
					<img
						id="home_mobile_main_banner"
						src={bannerPath}
						width={bannerDimensions[0]}
						height={bannerDimensions[1]}
						style={{
							width: `${bannerDimensions[0]}px`,
							height: `${bannerDimensions[1]}px`,
						}}
						loading="eager"
						alt="Home Banner"
						onClick={() => {
							window.location.href = `/loading.html?callbackUrl=${defaultUrlpath}`;
						}}
					/>
				</picture>
			</MainContainer>
		</>
	);
};

export default CoverBannerSection;
